import React, { Component } from 'react'
import { HashRouter, Route } from 'react-router-dom'
import Home from './Home'
import CaseStudiesScreen from './CaseStudiesScreen'
import EnterDetailsScreen from './details/EnterDetailsScreen'
import DisclaimerScreen from './DisclaimerScreen'
import PrivacyPolicyScreen from './PrivacyPolicyScreen'
import AGBScreen from './AGBScreen'

class App extends Component {
  render() {
    return [

      <HashRouter>
        <Route path='/' exact component={Home} />
        <Route path='/enter/cases' exact component={CaseStudiesScreen} />
        <Route path='/enter' exact component={EnterDetailsScreen} />
        <Route path='/haftung' exact component={DisclaimerScreen} />
        <Route path='/data-policy' exact component={PrivacyPolicyScreen} />
        <Route path='/agb' exact component={AGBScreen} />
      </HashRouter>, 
    ]
  }
}

export default App
