import React, { Component } from 'react'
import { Link} from 'react-router-dom';

 class EnterScreen extends Component {
    render() {
        let isActive = ' notActive'
        if (this.props.isActive) {
            isActive = ' isActive'
        } else {
            isActive = ' notActive'
        }

            return (
                <div className={'page animate-rise-up ' + this.props.type + isActive} id={this.props.type}>
                <div className='content'>
                    <div>
                        <h1>enter</h1>
                        <h2>
                        Wir kümmern uns um Ihr Gästemanagement.<br/> Damit Sie sich um Ihre Gäste kümmern können.
                        </h2>
                        <section>
                            <h4>
                            Professionelles, digitales Gästemanagement - maßgeschneidert auf Ihr Event
                            </h4>
                            <p>
                            enter ist unser selbstentwickeltes Tool für das digitale Einladungs- und Gästemanagement. Es bildet den Ausgangspunkt, um Anmeldewebseiten, digitale Einladungen und digitale Gästelisten entsprechend den speziellen Anforderungen Ihres Events individuell umzusetzen.
                            </p>
                        </section>
                        <section>
                            <h4>
                            Funktionsumfang von enter
                            </h4>
                            <p>
                            Vom Einladungsversand und RSVP, über die Gestaltung von Anmeldewebseiten, bis zum Chek-in am Veranstaltungstag - wir bilden mit enter das gesamte digitale Einladungs- und Gästemanagement ab und passen es Ihren Bedürfnissen an.
                            </p>
                            <div className='collection collection-5'>
                                <div className='element'>
                                    <img src="/assets/mail.svg" alt="digitaler Einladungsversandt"/>
                                    <p className="small">Digitaler Einladungsversandt</p>
                                </div>
                                <div className='element'>
                                    <img src="/assets/color.svg" alt="digitaler Einladungsversandt"/>
                                    <p className="small">Personalisierte Anmeldeseite</p>
                                </div>
                                <div className='element'>
                                    <img src="/assets/group.svg" alt="digitaler Einladungsversandt"/>
                                    <p className="small">Zugang für das Projektteam</p>
                                </div>
                                <div className='element'>
                                    <img src="/assets/devices.svg" alt="digitaler Einladungsversandt"/>
                                    <p className="small">Digitale Gästeliste</p>
                                </div>
                                <div className='element'>
                                    <img src="/assets/chart.svg" alt="digitaler Einladungsversandt"/>
                                    <p className="small">Abschließendes Reporting</p>
                                </div>
                            </div>
                            <p>
                                Erfahren Sie mehr in unserem Überblick:<br />
                              <a className="link" href="/assets/files/Übersicht - enter events by dcb.pdf" target="_blank"><button className="enter-button"><p>Überblick.pdf</p></button></a>
                            </p>
                        </section>
                        <section>
                            <p>
                                Erfahren Sie mehr über enter im Einsatz mit unseren
                            </p>
                            <p>
                                <Link to='/enter/cases' className="link"><button className="enter-button">Case Studies</button></Link>
                            </p>
                        </section>
                    </div>
                </div>
            </div>
            )

    }
}

export default EnterScreen
