import React, { Component } from 'react'

 class AboutUsScreen extends Component {

     
     render() {
        console.log(this.props.type ,this.props.isActive)
        let isActive = ' notActive'
        let {position} = this.props
        let activeStyle = {}

        if (this.props.isActive) {
            isActive = ' isActive '
        } else {
            isActive = ' notActive '
        }
        
        return (
            <div className={'page animate-rise-down ' + isActive + position} id={this.props.type} style={activeStyle}>
                <div className="content">
                    <div>
                        <h1>Über uns</h1>
                        <p>
                            Die Idee zur dev crew berlin entstand 2014 heraus aus unserer langjährigen Erfahrung im Bereich Event Management. Zu dieser Zeit bemerkten wir bei der Betreuung unserer Kundenprojekte zunehmenden Wunsch nach digitalen, spezifischen Leistungen, wenn es um die Organisation von Veranstaltungen ging.
                        </p>
                        <p>
                            Als Zusammenschluss aus Event Profis, Projekt Managern und Softwareentwicklern setzen wir seitdem mit unserem selbstentwickelten Tool enter das digitale Einladungs- und Gästemanagement für Events aller Größen um. Unsere Mission ist es dabei, den speziellen Kundenanforderungen sowie die Besonderheiten jeder Veranstaltung mit unseren individuellen Lösungen gerecht zu werden.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUsScreen
