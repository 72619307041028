import React from 'react'
import {Link} from 'react-router-dom';
import CaseStudySection from './CaseStudySection';

export default function CaseStudies() {

  return (<main className='page'>
    <div className='caseStudies'>

    
    <h1>Case Studies</h1>
    <img id="illustration" src="/assets/projectsCompleted.svg" alt="case studies illustration"/>
    <Link to='/'>
      <button className='enter-button red'>Home</button>
    </Link>

    <CaseStudySection title={"Fashion Shows internationaler Marken"} desc={"Jährlich präsentieren verschiedene internationale Modemarken über mehrere Tage ihre neuen Kollektionen. enter übernahm dabei über mehrere Veranstaltungstage hinweg für verschiedene Marken das Einladungs- und Einlassmanagement. Für den international tätigen Hauptsponsor entwickelten wir zusätzlich ein Tool zur Vergabe von Ticketkontingenten für die Shows und Abwicklung der Kartenvergabe."} content={(
        <div className='details'>
        <div className='conditions'>
          <h5>Besondere Anforderungen an enter</h5>
          <ol>
            <li>Kontingente für verschiedene Modenschauen festlegen</li>
            <li>Generierung verschiedener Bestätitgungsmail</li>
            <li>virtuelle Sitzplatzplanung</li>
          </ol>
        </div>
        <div className='eventInfo'>
          <div>
            <img src="/assets/manager.png" alt="Kunde"/>
            <h6>Kunde*</h6>
            <p>International tätiger Konzern (Durchführung im Auftrag der Event Agentur des Kunden)</p>
          </div>
          <div>
            <img src="/assets/branche.png" alt="Branche"/>
            <h6>Branche</h6>
            <p>Mode & Beauty</p>
          </div>
          <div>
            <img src="/assets/category.png" alt="Art der Veranstaltung"/>
            <h6>Art der Veranstaltung</h6>
            <p>Fashion Show</p>
          </div>
          <div>
            <img src="/assets/timeline.png" alt="Leistungszeitraum"/>
            <h6>Leistungszeitraum</h6>
            <p>2018, 2019, 2020</p>
          </div>

        </div>

        <div className="usingEnter">
          <h5>enter im Einsatz</h5>
          <p>Im Auftrag der Event Agentur des international tätigen Hauptsponsors übernahmen wir das anspruchsvolle Gästemanagement für die mehrtägige Veranstaltung.</p>

          <h6>Anforderung.
          </h6>
          <p>
            Für ein mehrtägiges Fashion Event verschiedener Modemarken wurde ein komplexes, digitales Einladungsmanagement benötigt, welches zusätzlich die Abwicklung der Kartenvergabe regeln sollte. Der Hauptsponsor erhielt Kontingente für jede Modenschau, die Freikarten konnten wiederum von den Tochterfirmen der Unternehmensgruppe in Anspruch genommen werden. Hierfür wurde ein Tool zur Abwicklung der Kartenvergabe benötigt, neben Bestätitigungsmails für den Gast war auch eine Benachrichtigung der Buchhaltung zur internen Verrechnung nötig.
          </p>
          <h6>Lösung.
          </h6>
          <p>
            Die beauftragte Event Agentur vertraute für die Umsetzung der komplexen, speziellen Anforderungen zum wiederholten Male enter.
          </p>
          <h6>Umsetzung.
          </h6>
          <p>
            Im Rahmen des Projektes entwickelten wir ein maßgeschneidertes Anmeldetool. Dieses ermöglichte den Tochterfirmen des Hauptsponsors Zugang zu den Kontingenten für die verschiedenen Modenschauen. Für die Shows konnten Karten bzw. Pakete gebucht werden und sobald das Kontingent für eine Modenschau aufgebraucht war, wurden weitere Buchungen ausgeschlossen. Da die bestellten Karten von den Tochterfirmen gezahlt werden mussten, wurden bei jeder Bestellung automatisch verschiedene Bestätigungsmails generiert - so z.B. auch eine Rechnung für die Buchhaltung. Die namentliche Anmeldung der Gäste musste nicht zeitgleich mit der Buchung erfolgen, sondern konnte auch zu einem späteren Zeitpunkt nachgeholt werden. Sobald dies erledigt war, erhielten die angemeldeten Gäste ihre jeweiligen Eintrittskarten per E-Mail. Des Weiteren erfolgte die Platzierung der Gäste über ein von uns entwickeltes digitales Tool zur Sitzplatzplanung. Somit konnten bis zum Showbeginn letzte Änderungen an der Platzierung der Gäste vorgenommen werden, um Leere in der ersten reihe zu vermeiden.
          </p>
        </div>

        <div className="specialSolution">
          <h5>Speziallösung: Vergabe von Ticketkontingenten</h5>
          <p>
            Im Rahmen der engen Zusammenarbeit mit der Veranstaltungsagentur, wurde eigens eine Lösung zur Ticketbuchung verschiedener Events im Rahmen der mehrtägigen Fashion Show nach Kundenanforderungen entwickelt: Das Tool ermöglichte Buchungen über die Veranstaltungswebseite unter Berücksichtigung verfügbarer Kontingente. Mit der Buchung wurden automatisch verschiedene Emails generiert: Darunter die Bestätigung für den Kunden oder eine Rechnung für die Buchhaltung. Die namentliche Anmeldung des Veranstaltungsgastes musste nicht zeitgleich zur Buchung erfolgen und konnte zu einem späteren Zeitpunkt einfach nachgeholt werden.
          </p>
        </div>

        <p className="extra">*Der international tätige Konzern sowie dessen Eventagentur stehen stellvertretend für ein in 2019 durchgeführtes Kundenprojekt, das wir aus Datenschutzgründen aktuell nicht mit Klarnamen benennen dürfen.</p>
      </div>)}/>

    <CaseStudySection title={"SMS-Benachrichtigungstool für Constructa-Neff"} desc={"Die sogenannte „Küchenmeile“ ist eine Küchenfachmesse für Handel und Industrie, die jährlich entlang der A30 nahe Osnabrück stattfindet. Auch unser langjähriger Kunde Constructa-Neff (NEFF) ist als Aussteller auf dieser Messe vertreten. Bereits mehrmals durften wir das Einladungs- und Gästemanagement von NEFF im Rahmen der Küchenmeile digitalisieren und entwickelten eigens als Add-on ein SMS Benachrichtigungstool."} content={(
      <div className='details'>
        <div className='conditions'>
          <h5>Besondere Anforderungen</h5>
          <ol>
            <li>digitales Einladungsmanagement</li>
            <li>SMS-Benachrichtigung beim Eintreffen besonderer Kunden</li>
            <li>dynamischer Inhalt der Benachrichtigung</li>
          </ol>
        </div>

        <div className='eventInfo eventInfo1'>
          <div>
            <img src="/assets/manager.png" alt="Kunde"/>
            <h6>Kunde</h6>
            <p>Constructa-Neff Vertriebs-GmbH</p>
          </div>
          <div>
            <img src="/assets/branche.png" alt="Branche"/>
            <h6>Branche</h6>
            <p>Elektro- und Einbau-Hausgeräte</p>
          </div>
          <div>
            <img src="/assets/category.png" alt="Art der Veranstaltung"/>
            <h6>Art der Veranstaltung</h6>
            <p>Küchenfachmesse</p>
          </div>
          <div>
            <img src="/assets/participants.png" alt="Teilnehmer"/>
            <h6>Teilnehmer</h6>
            <p>ca. 1500 an 5 Tagen</p>
          </div>
          <div>
            <img src="/assets/timeline.png" alt="Leistungszeitraum"/>
            <h6>Leistungszeitraum</h6>
            <p>2016, 2017, 2018, 2019</p>
          </div>
        </div>

        <div className="usingEnter">
          <h5>enter im Einsatz</h5>
          <p>Im Auftrag von NEFF übernahmen wir das digitale Einladungsmanagement für deren Auftritt auf der Küchenmeile.</p>
          <h6>Anforderung.</h6>
          <p>
            Neben dem digitalen Einladungs- und Zutrittsmanagement benötigte NEFF ein Informationssystem für die eigenen Mitarbeiter vor Ort, welches diese zeitnah über die Ankunft der Vertriebspartner und Kunden informieren sollte.
          </p>
          <h6>Lösung.</h6>
          <p>
            Die Umsetzung der speziellen Anforderungen erfolgte mit enter, dem digitalen Einladungs- und Gästemanagement der dev crew berlin.
          </p>
          <h6>Umsetzung.</h6>
          <p>
            Die Einladungen zur Küchenmeile wurden mit Hilfe von enter versandt. Die Anmeldung der Gäste erfolgte über eine eigens eingerichtete Akkreditierungsseite unter Abfrage weiterer Informationen. Auf Wunsch des Kunden entwickelten wir zusätzlich ein SMS-Benachrichtigungstool als Add-on zu unserem regulären Gästemanagement. Mit dem Check-in des Gastes wurde automatisch eine SMS an den verantwortlichen Key Account Manager gesendet. Der Inhalt der Nachricht wurde dynamisch aus dem System erstellt und enthielt z.B. Angaben wie Kundenname, Firmenname, Name der Begleitperson sowie andere Informationen. Der Key Account Manager konnte nach Erhalt der relevanten Information den jeweiligen Gast direkt in Empfang nehmen.</p>
        </div>
        <div className="specialSolution">
          <h5>Speziallösung: SMS Benachrichtigungstool</h5>
          <p>Das für die Anforderungen von NEFF entwickelte SMS-Benachrichtigungstool bietet viele weitere Einsatzmöglichkeiten: Auch Gästen können simultan zum Checkin eine Begrüßung, die Zugangsdaten für das WLAN vor Ort oder andere relevante Informationen per SMS erhalten.
          </p>
        </div>
      </div>)}/>

    <CaseStudySection title={"Preisverleihung eines Lifestyle Magazins"} desc={"Jedes Jahr treffen sich nationale und internationale Persönlichkeiten aus der Show-Branche zu einer Award-Verleihung, um die erfolgreichsten unter sich zu feiern. Das Einladungs- und Einlassmanagement zum dritten Mal in Folge von enter übernommen."} content={(
      <div className='details'>
        <div className='conditions'>
          <h5>Besondere Anforderungen</h5>
          <ol>
            <li>Dynamische Ansicht der Anmeldeseite für verschiedene Teilnehmerkategorien</li>
            <li>Möglichkeit, nachträglich Begleitpersonen anzumelden</li>
            <li>Digitale Sitzplanung über individuelle Abbildung des Veranstaltungssaals</li>
          </ol>
        </div>
        <div className='eventInfo eventInfo1'>
          <div>
            <img src="/assets/manager.png" alt="Kunde"/>
            <h6>Kunde*</h6>
            <p>Lifestyle Magazin eines deutschen Verlagshauses (Durchführung im Auftrag der Event Agentur des Kunden)</p>
          </div>
          <div>
            <img src="/assets/branche.png" alt="Branche"/>
            <h6>Branche</h6>
            <p>Medien/Verlag</p>
          </div>
          <div>
            <img src="/assets/category.png" alt="Art der Veranstaltung"/>
            <h6>Art der Veranstaltung</h6>
            <p>Gala/Preisverleihung</p>
          </div>
          <div>
            <img src="/assets/participants.png" alt="Teilnahmer"/>
            <h6>Teilnehmer</h6>
            <p>1.000+ geladene Gäste, 100 Pressevertreter</p>
          </div>
          <div>
            <img src="/assets/timeline.png" alt="Leistungszeitraum"/>
            <h6>Leistungszeitraum</h6>
            <p>2017, 2018, 2019</p>
          </div>
        </div>

        <div className="usingEnter">
          <h5>enter im Einsatz</h5>
          <p>Im Auftrag der Event Agentur des Kunden übernahmen wir das digitale Gästemanagement und die virtuelle Sitzplatzplanung für die jährlich stattfindende Preisverleihung</p>
          <h6>Anforderung.</h6>
          <p>
            Neben rund 1.000 geladenen Gästen, Preisträgern und Sponsoren wurden auch fast 100 Pressevertreter eingeladen. Die Anmeldeseite für das Event sollte je nach Teilnehmer-Kategorie die entsprechend passenden Informationen und Optionen anzeigen. Einige Journalisten sollten sich ihren Sitzbereich im Veranstaltungssaal selbst auswählen dürfen, andere Zugang zum roten Teppich erhalten. Für Presseredakteure sollte zudem die Möglichkeit bestehen, ihr Kamerateam nachträglich über die Anmeldeseite zu registrieren. Zur Platzierung der geladenen Gäste und Sponsoren wurde ein digitales Tool zur Planung der Sitzordnung benötigt. Dieses sollte die individuellen Gegebenheiten des Veranstaltungsortes abbilden.
          </p>
          <h6>Lösung.</h6>
          <p>
            Wie schon in den beiden Jahren zuvor, entschied sich die beauftragte Event Agentur bei der Umsetzung des komplexen Einladungsmanagements erneut für die digitale Gästeverwaltung mit enter.
          </p>
          <h6>Umsetzung.</h6>
          <p>enter ermöglichte es, den Inhalt auf der Anmeldeseite dynamisch an die verschiedenen Teilnehmer-Kategorien anzupassen. So wurden den Teilnehmer immer die jeweils relevanten Optionen angezeigt sowie die benötigten Informationen abgefragt. Am Veranstaltungstag erfolgte die Akkreditierung der angemeldeten Teilnehmer vor Ort an 7 Stationen ebenfalls digital mit enter. Neben Informationen zur Teilnehmer-Kategorie wurde ggf. der zugewiesene Sitzplatz im Saal angezeigt, sodass der Gast die passende Bändchenfarbe erhielt und korrekt platziert werden konnte. Der digitale Seating Plan von enter erlaubte den Veranstaltern auch kurzfristige Änderungen an der Sitzordnung, während der Einlass bereits lief. Währenddessen erhielt der Auftraggeber separat Zugang zu einer mobilen App mit Echtzeit-Informationen zum aktuellen Check-In-Prozess, z.B. der Anzahl der eingecheckten Gäste.</p>
        </div>
        <div className="specialSolution">
          <h5>Speziallösung: Automatisierung mittels Bot</h5>
          <p>
            Ein eigens kreierter Bot half bei der Automatisierung des Freigabeprozesses: Sobald ein Gast sich anmeldete und eine Begleitperson hinzufügte, konnte der Veranstalter diese Begleitung in enter überprüfen und freigeben. Nach Freigabe wurde die Begleitperson automatisch als eigener Datensatz auf der Gästeliste angelegt und bekam alle relevanten Informationen an die persönliche E-Mailadresse mitgeteilt. Dieser automatisierte Prozess erfolgte für reguläre Gäste sowie für Presseteams.
          </p>
        </div>
        <p className="extra">*Das deutsche Verlagshaus sowie dessen Eventagentur stehen stellvertretend für ein in 2019 durchgeführtes Kundenprojekt, das wir aus Datenschutzgründen aktuell nicht mit Klarnamen benennen dürfen.</p>
      </div>)}/>

    <CaseStudySection title={"Innovationstag eines Kreditinstituts"} desc={"Ein international tätiges Kreditinstitut führt jährlich eine Innovationstagung durch, bei der Mitarbeiter an themenspezifischen Workshops teilnehmen können. Das Einladungs- und Einlassmanagement wurde dabei von enter übernommen."} content={(
      <div className='details'>
        <div className='conditions'>
          <h5>Besondere Anforderungen</h5>
          <ol>
            <li>Automatische Verwaltung: Kontingente für auf Anmeldeseite angebotene Workshops</li>
            <li>Automatisierter Ausdruck von Namensschildern beim Gäste-Check-in</li>
            <li>Erneute Akkreditierung vor Zutritt zu Workshops</li>
          </ol>
        </div>
        <div className='eventInfo eventInfo1'>
          <div>
            <img src="/assets/manager.png" alt="Kunde"/>
            <h6>Kunde*</h6>
            <p>international tätiger Finanzdienstleister (Durchführung im Auftrag der Event Agentur des Kunden)</p>
          </div>
          <div>
            <img src="/assets/branche.png" alt="Branche"/>
            <h6>Branche</h6>
            <p>Finanzdienstleistungen B2C/B2B</p>
          </div>
          <div>
            <img src="/assets/category.png" alt="Art der Veranstaltung"/>
            <h6>Art der Veranstaltung</h6>
            <p>Workshop</p>
          </div>
          <div>
            <img src="/assets/participants.png" alt="Teilnahmer"/>
            <h6>Teilnehmer</h6>
            <p>400</p>
          </div>
          <div>
            <img src="/assets/timeline.png" alt="Leistungszeitraum"/>
            <h6>Leistungszeitraum</h6>
            <p>2019</p>
          </div>
        </div>

        <div className="usingEnter">
          <h5>enter im Einsatz</h5>
          <p>Im Auftrag der Event Agentur des Kunden übernahmen wir das anspruchsvolle Gästemanagement für den Innovationstag des Kreditinstituts.</p>
          <h6>Anforderung.</h6>
          <p>
            Der Innovationstag wurde für ca. 400 interne Mitarbeiter durchgeführt. Im Vorfeld konnten diese sich über die Anmeldeseite für verschiedene Workshops registrieren können. Sobald ein Workshop die maximale Teilnehmerzahl erreicht hat, sollte die Registrierung dafür über die Veranstaltungsseite nicht mehr möglich sein. Auf dem Innovationstag selbst sollte der Einlass per digitaler Gästeliste erfolgen. Mit Akkreditierung vor Ort wurde für jeden Teilnehmer außerdem ein Namensschild benötigt. Zusätzlich sollte für jeden Workshop eine weitere Akkreditierung durchgeführt werden, um zu vermeiden, dass Mitarbeiter falsche Vorträge besuchen.
          </p>
          <h6>Lösung.</h6>
          <p>
            Die beauftragte Event Agentur entschied sich bei der Umsetzung des komplexen Einladungsmanagements inkl. Der Verwaltung von Kontingenten für die digitale Gästeverwaltung mit enter.
          </p>
          <h6>Umsetzung.</h6>
          <p>
            enter ermöglichte es, die Inhalte auf der Anmeldeseite entsprechend der Anforderungen darzustellen und Kontingente für die einzelnen Workshops festzulegen. Mit der Anmeldung wurde den Mitarbeitern eine Bestätigungsmail gesendet, die einen QR Code enthielt. Dieser diente am Veranstaltungstag beim Check-in zur digitalen Akkreditierung per Scan. Simultan mit dem Check-in-Vorgang wurde automatisch der Druck eines Namensetiketts für den Gast veranlasst. Manuelle Schritte oder unnötiges Suchen nach vorgedruckten Schildern wurden so vermieden. Auch die doppelte Akkreditierung am Eingang und vor dem Zutritt zum jeweiligen Workshop ließ sich dank enter einfach umsetzen.
          </p>
        </div>
        <p className="extra">*Der international tätige Konzern sowie dessen Eventagentur stehen stellvertretend für ein in 2019 durchgeführtes Kundenprojekt, das wir aus Datenschutzgründen aktuell nicht mit Klarnamen benennen dürfen.</p>
      </div>)}/>
      </div>
  </main>)
}
