import React, { Component } from "react";

import AboutUsScreen from "./AboutUsScreen";
import ContactScreen from "./ContactScreen";
import EnterScreen from "./EnterScreen";
import ReferencesScreen from "./ReferencesScreen";

export default class Home extends Component {
  state = {
    isActive: "",
    isOpen: false,
  };

  // toggle
  toggleActive(type) {
    if (this.state.isActive === type) {
      this.setState({
        isActive: "",
        isOpen: false,
      });
    } else {
      this.setState({
        isActive: type,
        isOpen: true,
      });
    }
  }

  isActive(type) {
    if (type === this.state.isActive) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    let TOPLEFT = "top-left";
    let TOPRIGHT = "top-right";
    let BOTTOMLEFT = "bottom-left";
    let BOTTOMRIGHT = "bottom-right";

    return (
      <div id="content">
        <div className={"dcb-logo " + this.state.isActive} id="logo">
          <img
            src="/assets/devcrewlogo_facebook.png"
            alt="dev crew berlin Logo"
          />
          <h5>web & mobile software development</h5>
        </div>
        {/*Toggle is-active class to hide not-active buttons in mobile view*/}
        <div
          id="elements-wrapper"
          className={this.state.isOpen ? "is-active" : ""}
        >
          <button
            className={
              "homepage-button animate-rise-down " +
              (this.state.isActive === "top-left"
                ? "active top-left"
                : "notActive top-left")
            }
            onClick={() => {
              this.toggleActive(TOPLEFT);
            }}
          >
            Über uns<span></span>
            <span></span>
          </button>
          <AboutUsScreen
            type={TOPLEFT}
            position=" top-left "
            isActive={this.isActive(TOPLEFT)}
          />
          <button
            className={
              "homepage-button animate-rise-down " +
              (this.state.isActive === "top-right"
                ? "active top-right"
                : "notActive top-right")
            }
            onClick={() => {
              this.toggleActive(TOPRIGHT);
            }}
          >
            Kontakt<span></span>
            <span></span>
          </button>
          <ContactScreen
            type={TOPRIGHT}
            position=" top-right "
            isActive={this.isActive(TOPRIGHT)}
          />
          <button
            className={
              "homepage-button animate-rise-up " +
              (this.state.isActive === "bottom-left"
                ? "active bottom-left"
                : "notActive bottom-left")
            }
            onClick={() => {
              this.toggleActive(BOTTOMLEFT);
            }}
          >
            enter<span></span>
            <span></span>
          </button>
          <EnterScreen
            type={BOTTOMLEFT}
            position=" bottom-left "
            isActive={this.isActive(BOTTOMLEFT)}
          />
          <button
            className={
              "homepage-button animate-rise-up " +
              (this.state.isActive === "bottom-right"
                ? "active bottom-right"
                : "notActive bottom-right")
            }
            onClick={() => {
              this.toggleActive(BOTTOMRIGHT);
            }}
          >
            Leistungen<span></span>
            <span></span>
          </button>
          <ReferencesScreen
            type={BOTTOMRIGHT}
            position=" bottom-right "
            isActive={this.isActive(BOTTOMRIGHT)}
          />
        </div>
      </div>
    );
  }
}
