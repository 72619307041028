import React from 'react'

export default function EnterDetailsScreen() {
    return (
        <main className='page'>
            <img src="/assets/enter_logo.svg" alt="enter" className="h1"/>
            <h2>Wir kümmern uns um Ihr Gästemanagement.<br/>Damit Sie sich um Ihre Gäste kümmern können.</h2>
            <section>
                <h2>Professionelles, digitales Gästemanagement - maßgeschneidert auf Ihr Event</h2>
                <p>
                    enter ist unser selbstentwickeltes Tool für das digitale Einladungs- und Gästemanagement. Es bildet den Ausgangspunkt, um Anmeldewebseiten, digitale Einladungen und digitale Gästelisten entsprechend den speziellen Anforderungen Ihres Events individuell umzusetzen.
                </p>
                <h2>Funktionsumfang von enter</h2>
                <p>
                Vom Einladungsversand und RSVP, über die Gestaltung von Anmeldewebseiten, bis zum Chek-in am Veranstaltungstag - wir bilden mit enter das gesamte digitale Einladungs- und Gästemanagement ab und passen es Ihren Bedürfnissen an. 
                </p>
                <div className='collection collection-5'>
                            <div className='element'>
                                <img src="/assets/mail.svg" alt="digitaler Einladungsversandt"/>
                                <p className="small">Digitaler Einladungsversandt</p>
                            </div>
                            <div className='element'>
                                <img src="/assets/color.svg" alt="digitaler Einladungsversandt"/>
                                <p className="small">Personalisierte Anmeldeseite</p>
                            </div>
                            <div className='element'>
                                <img src="/assets/group.svg" alt="digitaler Einladungsversandt"/>
                                <p className="small">Zugang für das Projektteam</p>
                            </div>
                            <div className='element'>
                                <img src="/assets/devices.svg" alt="digitaler Einladungsversandt"/>
                                <p className="small">Digitale Gästeliste</p>
                            </div>
                            <div className='element'>
                                <img src="/assets/chart.svg" alt="digitaler Einladungsversandt"/>
                                <p className="small">Abschließendes Reporting</p>
                            </div>
                        </div>
            </section>
        </main>
    )
}
