import React, { Component } from 'react'

 class ReferencesScreen extends Component {

    render() {
        let isActive = ' notActive'
        if (this.props.isActive) {
            isActive = ' isActive'
        } else {
            isActive = ' notActive'
        }

        return (
            <div className={'page animate-rise-up ' + this.props.type + isActive} id={this.props.type}>
            <div className="content">
                <div>
                    <h1>Leistungen</h1>
                    <h2>Professionelles, digitales Gästemanagement - maßgeschneidert auf Ihr Event</h2>
                    <section>
                        <p>
                            Unsere Leistungen umfassen das Umsetzen spezieller, digitaler Lösungen für das Einladungs- und Gästemanagement. Dafür haben wir eigens ein Softwaretool namens enter entwickelt.
                        </p>
                        <p>
                            Mit enter können wir Ihre Wünsche individuell umsetzen und an die Anforderungen Ihres Events anpassen. Als Agentur übernehmen wir das digitale Einladungs- und Gästemanagement für Sie. Dabei entscheiden Sie selbst, welche Leistungen Sie in Anspruch nehmen möchten und welche spezifischen Lösungen Sie zusätzlich benötigen.
                        </p>
                        {/* <p>
                            Erfahren Sie mehr über enter im Einsatz in unseren
                        </p>
                        <p>
                            <Link to='/enter/cases' className="link"><button className="enter-button">Case Studies</button></Link>
                        </p> */}
                    </section>
                    <section>
                        <h3>Unsere Leistungen umfassen unter anderem:</h3>
                        <h4>Digitales Einladungsmanagement</h4>
                        <ul>
                            <li>Aufsetzen und Verwalten eines Online Akkreditierungsportals</li>
                            <li>Digitaler Einladungsversand</li>
                            <li>Apps zur digitalen Akkreditierung vor Ort</li>
                            <li>Bereitstellung aktueller Daten und Datenauswertung</li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
        )
    }
}

export default ReferencesScreen
