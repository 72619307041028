import React from 'react'
import { Link} from 'react-router-dom';

export default function PrivacyPolicyScreen() {
    return (
        <main className='page'>
            <h1>Datenschutz</h1>
            <p>
            Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung
            und Verwendung personenbezogener Daten durch die dev crew berlin ug informieren. Der Websitebetreiber nimmt
            Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der
            gesetzlichen Vorschriften. Da durch neue Technologien und die ständige Weiterentwicklung dieser Webseite
            Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung
            in regelmäßigen Abständen wieder durchzulesen.
            </p>
            <p>
            Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.
            Zugriffsdaten Wir, der Websitebetreiber, erheben aufgrund unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO)
            Daten über Zugriffe auf die Website und speichern diese als „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten
            werden so protokolliert: Besuchte Website, Uhrzeit zum Zeitpunkt des Zugriffes, Menge der gesendeten Daten in Byte, Quelle/Verweis,
            von welchem Sie auf die Seite gelangten, Verwendeter Browser, Verwendetes Betriebssystem, Verwendete IP-Adresse.
            </p>
            <p>
            Die Server-Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht.
            Die Speicherung der Daten erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können.
            Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der
            Vorfall endgültig geklärt ist.
            </p>
            <p>
            Erfassung und Verarbeitung personenbezogener Daten Der Websitebetreiber erhebt,
            nutzt und gibt Ihre personenbezogenen Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt
            ist oder Sie in die Datenerhebung einwilligen. Als personenbezogene Daten gelten sämtliche Informationen,
            welche dazu dienen, Ihre Person zu bestimmen und welche zu Ihnen zurückverfolgt werden können –
            also beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.
            Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu machen.
            Zur Verbesserung unseres Online-Angebotes speichern wir jedoch (ohne Personenbezug)
            Ihre Zugriffsdaten auf diese Website. Zu diesen Zugriffsdaten gehören z. B.
            die von Ihnen angeforderte Datei oder der Name Ihres Internet-Providers. Durch die Anonymisierung der Daten sind Rückschlüsse auf Ihre Person nicht möglich.
            </p>
            <h4>Haftung für Links</h4>
            <p>Das Angebot der dev crew berlin ug (haftungsbeschränkt) enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
            Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. </p>
            <h4>Urheberrecht</h4>
            <p>
            Die durch die dev crew berlin ug (haftungsbeschränkt) erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
            Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
            des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
            privaten, nicht kommerziellen Gebrauch gestattet.
            </p>
            <Link to='/'><button className='enter-button red'>Home</button></Link>
        </main>
    )
}
