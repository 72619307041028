import React, { Component } from 'react'
import { Link} from 'react-router-dom';

 class ContactScreen extends Component {

    render() {
        let isActive = ' notActive'
        if (this.props.isActive) {
            isActive = ' isActive'
        } else {
            isActive = ' notActive'
        }

            return (
                <div className={'page animate-rise-down ' + this.props.type + isActive} id={this.props.type}>
                <div className="content">
                    <div>
                        <h1>Kontakt</h1>
                        <section>
                            <h4>Kontakt:</h4>
                            <p>
                                Solmsstr. 24 10961 Berlin
                            </p>
                            <p>
                                +49 172 4001889<br/>
                                <a href="mailto:postmaster@dcb.ug" target="_blank" rel="noopener noreferrer">postmaster@dcb.ug</a>
                            </p>
                            
                            <h4>Impressum:</h4>
                            <p>
                                dev crew berlin ug (haftungsbeschränkt)<br/>
                                Solmsstr. 24<br/>
                                10961 Berlin <br/>
                            </p>
                            <p>
                                Telefon: +49 172 4001889<br />
                                E-Mail: <a href="maito:postmaster@dcb.ug" target="_blank" rel="noopener noreferrer">postmaster@dcb.ug</a>
                            </p>
                            <p>
                                Geschäftsführer Michael Quade<br/>
                                Amtsgericht Berlin Charlottenburg HRB 169265 B<br/>
                                Umsatzsteuer-Identifikationsnummer: DE 301691218
                            </p>
                        </section>
                        <section>
                            <div>
                                <Link to='/agb'>AGBs</Link>
                                <span> &#183; </span>
                                <Link to='/data-policy'>Datenschutz</Link>
                                <span> &#183; </span>
                                <Link to='/haftung'>Haftungsausschluss</Link>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            )

    }
}

export default ContactScreen
