import React, {useState} from 'react'

export default function CaseStudySection(props) {
  const [isCollapsed, setCollapsedState] = useState("collapsed");

  function toggleCollapse() {
    setCollapsedState(isCollapsed === "" ? "collapsed" : "");
  }

  return (
    <section className={isCollapsed}>
      <div className='subHeader'>
        <div>
          <h4>{props.title}</h4>
          <button onClick={() => { toggleCollapse() }}>
            <span></span>
            <span></span>
          </button>
        </div>
        <p>{props.desc}</p>
      </div>
      {props.content}
  </section>)
}
