import React from 'react'
import { Link} from 'react-router-dom';
import PDF from '../static/resources/AGBs_DCB.pdf';

export default function AGBScreen() {
    return (
        <main className='page'>
            <h4>Allgemeine Geschäftsbedingungen<br/>
                dev crew berlin ug (haftungsbeschränkt)<br/>
                Kurfürstendamm 45, 10719 Berlin
            </h4>

            <h4>1. Geltungsbereich/Allgemeines</h4>
            <p>
                1.1 Die nachstehenden Allgemeinen Geschäftsbedingungen - nachfolgend „AGB“ genannt - gelten für sämtliche Vertragsbeziehungen, die durch die dev crew berlin ug (haftungsbeschränkt) - nachfolgend „die Agentur“ genannt - eingegangen werden.<br/><br/>
                1.2 Andere Allgemeine Geschäftsbedingungen mit Bestimmungen, welche abweichende oder entgegenstehende Regelungsinhalte haben, finden auf diese Vertragsverhältnisse keine Anwendung, es sei denn, die Agentur hat ihre Einbeziehung in den Vertrag ausdrücklich schriftlich zugestimmt.<br/><br/>
                1.3 Die AGB der Agentur sind auch zum <a className="download" href={PDF} target="_blank" rel="noopener noreferrer">Download</a> auf der Website www.dcb.ug bereitgestellt.<br/><br/>
            </p>

            <h4>2. Zustandekommen des Vertragsverhältnisses</h4>
            <p>
                2.1 Auf Basis des Kostenvoranschlags erteilt der Auftraggeber den Auftrag.<br/><br/>
                2.2 Ein Vertrag kommt mit der schriftlichen Auftragsbestätigung durch die Agentur zustande.<br/><br/>
                2.3 Der Umfang des Auftrags wird regelmäßig gekennzeichnet durch ein Leistungsverzeichnis.<br/><br/>
                2.4 Die Agentur ist für die Erfüllung seiner vertraglichen Verpflichtungen berechtigt, Dritte als Erfüllungsgehilfen einzuschalten. Die Agentur wird die hierfür erforderlichen Verträge im eigenen Namen schließen.<br/><br/>
            </p>

            <h4>3. Kostenvoranschlag/Preise</h4>
            <p>
                3.1 Die Agentur erstellt auf Basis der Kundenanforderungen und des Briefings einen Kostenvoranschlag.<br/><br/>
                3.2 Alle in dem Kostenvoranschlag von der Agentur genannten Kosten, insbesondere Fremdkosten für die Produktion, sind sorgfältig recherchiert, können aber in der tatsächlichen Höhe von den in dem Kostenvoranschlag genannten Beträgen um bis zu 10 % nach oben hin abweichen. Abweichungen im vorgenannten Rahmen sind vom Auftraggeber zu tragen.<br/><br/>
                3.3 Soweit Kostensteigerungen eintreten sollten oder für die Agentur erkennbar werden, wird die Agentur den Auftraggeber hierüber durch Übersendung eines aktualisierten Kostenvoranschlags informieren. Sofern der Auftraggeber zusätzliche Leistungen wünscht, kann die Agentur zusätzliches Honorar und zusätzlich anfallende Fremdkosten verlangen.<br/><br/>
            </p>

            <h4>4. Zahlungsbedingungen</h4>
            <p>
                4.1 Die genannten Kosten bzw. Preise sind Nettopreise und verstehen sich zuzüglich der jeweils am Tage der Rechnungsstellung gültigen gesetzlichen Umsatzsteuer.<br/><br/>
                4.2 Es gelten folgende Zahlungsmodalitäten:<br/><br/>
                4.1.1. Nach Vertragsabschluss werden 60 % des Gesamtbudgets sofort fällig und sind innerhalb von 10 Tagen ohne Abzug an die Agentur zu entrichten.<br/><br/>
                4.1.2. Mit Erfüllung der eingegangenen Verpflichtungen durch die Agentur wird der Restbetrag in Höhe von 40 % des Auftragswerts fällig und ist sodann innerhalb von 14 Tagen ohne Abzug zahlbar.<br/><br/>
                4.3 Im Falle des Verzugs gelten die gesetzlichen Verzugsbestimmungen.<br/><br/>
            </p>

            <h4>5. Beendigung/Kündigung</h4>
            <p>
                5.1 Soweit der Auftraggeber sich mit seinen Zahlungen in Verzug befinden sollte, ist die Agentur berechtigt, den Auftrag fristlos zu kündigen. Der Auftraggeber ist sodann verpflichtet, die Agentur von sämtlichen Verbindlichkeiten freizustellen bzw. bereits geleistete Forderungen zu ersetzen, die diese in Ausführung des Auftrags bereits eingegangen ist bzw. geleistet hat. Darüber hinaus ist der Auftraggeber zur Zahlung einer anteiligen Vergütung an die Agentur verpflichtet. Die Vergütung richtet sich nach dem Stand der Vorbereitungen und der erbrachten Leistungen des Projekts.<br/><br/>
                5.2 Die Agentur behält sich ein Rücktrittsrecht vor, wenn die Veranstaltungsdurchführung aus technischen, inhaltlichen, programmlichen oder genehmigungsrechtlichen Gründen unzumutbar ist.<br/><br/>
                5.3 Die Agentur behält sich ein Rücktrittsrecht vor, wenn Besorgnis besteht, dass das Event oder der Werbebeitrag aufgrund seines Inhalts oder der Herkunft gegen ein Gesetz oder behördliche Zustimmung verstößt.<br/><br/>
                5.4 Die Agentur behält sich ein Rücktrittsrecht vor, wenn die Anweisung eines Kunden zu der Begehung einer Straftat oder Ordnungswidrigkeit führen würde.<br/><br/>
                5.5 Der Auftraggeber ist berechtigt, jederzeit vom Vertrag zurückzutreten bzw. zu kündigen. In diesem Fall werden dem Auftraggeber nur diejenigen Kosten in Rechnung gestellt, welche die Agentur nachweislich durch Leistung Dritter, die von der Agentur im Rahmen der Vorbereitung bzw. Durchführung der Veranstaltung beauftragt wurden, entstanden sind.<br/>
                Darüber hinaus hat der Auftraggeber an die Agentur folgende anteilige Vergütung zu zahlen:<br/><br/>
                5.5.1 Soweit er mehr als sechs Monate vor Beginn der Veranstaltung vom Vertrag zurücktritt bzw. kündigt, 10 % der im Kostenvoranschlag ausgewiesenen Vergütung.<br/><br/>
                5.5.2 Soweit er mehr als drei Monate und bis zu sechs Monate vor Beginn der Veranstaltung zurücktritt bzw. kündigt, 25 % der im Kostenvoranschlag ausgewiesenen Vergütung.<br/><br/>
                5.5.3 Soweit der Rücktritt bzw. die Kündigung mehr als drei Wochen und bis zum drei Monate vor Beginn der Veranstaltung erfolgt, 60 % der im Kostenvoranschlag ausgewiesenen
                Vergütung.<br/><br/>
                5.5.4 Soweit der Rücktritt bzw. die Kündigung mehr als eine Woche und bis zu drei Wochen vor Beginn der Veranstaltung erfolgt, 80 % der im Kostenvoranschlag ausgewiesenen Vergütung.<br/><br/>
                5.5.5 Soweit der Rücktritt bzw. die Kündigung eine Woche vor Beginn der Veranstaltung oder kurzfristiger erfolgt, 100 % der im Kostenvoranschlag ausgewiesenen Vergütung.<br/><br/>
            </p>

            <h4>6. Aufrechnung/Zurückbehaltungsrecht</h4>
            <p>
                6.1 Das Recht zur Aufrechnung steht dem Auftraggeber nur dann zu, wenn etwaige Gegenansprüche rechtskräftig festgestellt, unbestritten oder von der Agentur anerkannt worden sind.<br/><br/>
                6.2 Der Agentur steht ein Zurückbehaltungsrecht zu, soweit der Auftraggeber seiner Verpflichtung zur Zahlung fälliger Abschläge nicht nachkommt.<br/><br/>
            </p>

            <h4>7. Haftung und Gewährleistung</h4>
            <p>
                7.1 Die Haftung der Agentur ist unabhängig von der Art der Pflichtverletzung, einschließlich unerlaubter Handlung, ausgeschlossen, soweit nicht vorsätzliches oder grob fahrlässiges Handeln vorliegt.<br/><br/>
                7.2 Bei Verletzung wesentlicher Vertragspflichten haftet die Agentur für jede Fahrlässigkeit. Sofern nicht vorsätzliches Handeln vorliegt, ist die Schadensersatzhaftung beschränkt auf den vorhersehbaren, typischerweise eintretenden Schaden. Ansprüche auf entgangenen Gewinn, aus Schadenersatzansprüchen Dritter sowie auf sonstige mittelbare und Folgeschäden, können nicht verlangt werden, es sei denn, ein von der Agentur garantiertes Beschaffenheitsmerkmal bezweckt gerade, den Auftraggeber gegen solche Schäden abzusichern.<br/><br/>
                7.3 Die vorstehenden Haftungsbeschränkungen und -ausschlüsse gelten nicht für Ansprüche die wegen arglistigen Verhaltens der Agentur entstanden sind, für Ansprüche nach dem Produkthaftungsgesetz sowie Schäden aus der Verletzung des Lebens, des Körpers der Gesundheit.<br/><br/>
                7.4 Die Agentur steht nicht für die Folgen verspäteter oder wesentlich erschwerter Leistungserbringung oder der Unmöglichkeit der Leistung ein, soweit dies auf Umständen beruht, die:<br/><br/>
                7.4.1 außerhalb des betrieblichen Bereichs der Agentur liegen, insbesondere im Bereich des Auftraggebers und von der Agentur nicht schuldhaft herbeigeführt worden sind, Naturkatastrophen oder sonstigen Fällen höherer Gewalt, bei hoheitlichen Eingriffen und bei Arbeitskämpfen;
                oder<br/><br/>
                7.4.2 die zwar innerhalb des betrieblichen Bereichs der Agentur liegen, jedoch von dieser nicht zu vertreten sind; insbesondere bei Beeinträchtigungen des Betriebsablaufs aufgrund höherer Gewalt, aufgrund hoheitlicher Eingriffe oder aufgrund von Arbeitskämpfen.<br/><br/>
                7.5 Der Auftraggeber haftet für sämtliche an den benutzten Gebäuden, Einrichtungen, Örtlichkeiten und dergleichen sowie an Ausstellungsgegenständen und verwendeten oder zur Aufbewahrung überlassenen Sachen bei Durchführung der Veranstaltung entstehenden Schäden, soweit diese durch seine Mitarbeiter oder Gäste herbeigeführt werden, wie auch für ein Abhandenkommen der Sachen. Der Auftraggeber unterliegt während der Veranstaltung dem Hausrecht der Gesellschaft, bei der die Veranstaltungsräume gemietet
                wurden.<br/><br/>
            </p>

            <h4>8. Haftung</h4>
            <p>
                8.1 Der Kunde ist verpflichtet entsprechende Maßnahmen zur Schadensabwehr und Schadensminderung vorzunehmen.<br/><br/>
                8.2 Die Haftung der Agentur für Personen-, Sach- und Vermögensschäden ist ausgeschlossen, insbesondere für solche aus Unfällen, Beschädigung, Verlust und Diebstahl, sofern die Agentur kein grob fahrlässiges oder vorsätzliches Verhalten nachgewiesen werden kann.<br/><br/>
                8.3 Die von der Agentur beauftragte Dritte sind nicht Erfüllungsgehilfen der Agentur. Die Agentur haftet nicht für Schäden die durch Mängel, Verzug oder Nichterfüllung von Verpflichtungen der Dritten entstehen. Die Agentur wird die Interessen des Kunden stets vertreten um etwaige Forderungen des Kunden gegenüber Dritten geltend zu machen. Auf Anforderung des Kunden wird die Agentur eigene Ansprüche gegen Drittunternehmer an den Kunden abtreten.<br/><br/>
                8.4 Die Agentur haftet nicht für Schäden, die dem Kunden dadurch entstehen, dass künstlerische Darbietungen oder dramaturgische Ablaufgestaltungen nicht den vom Kunden erwarteten Erfolg haben, oder kommunikative Ziele erreichen.<br/><br/>
                8.5 Die Agentur haftet nicht für die erfolgreiche Durchführung von Ton-, Videoaufzeichnugen oder Fotoaufnahmen, welche von dem Kunden vorgenommen werden.<br/><br/>
                8.6 Die Agentur haftet nicht für Angaben, die sie von dem Kunden, insbesondere über Eigenschaften des Produkts, erhält. Der Kunde stellt die Agentur in diesem Fall von Ansprüchen Dritter frei.<br/><br/>
                8.7 Die Agentur haftet nicht für etwaige Farbabweichungen, Veränderungen des Firmenlogos des Kunden oder sonstige grafische Unstimmigkeiten von Drucken und anderen Werbemaßnahmen des Kunden.<br/><br/>
            </p>

            <h4>9. Versicherungen</h4>
            <p>
                Soweit die Durchführung von Veranstaltungen besondere Risiken in sich bergen, kann die Agentur vom Auftraggeber eine gesonderte Haftungsfreistellung oder alternativ den Abschluss einer das Risiko abdeckenden Versicherung auf Kosten des Auftraggebers verlangen. Über etwaige Risiken hat der Auftraggeber die Agentur zu informieren, sobald sie ihm bekannt werden.<br/><br/>
            </p>

            <h4>10. Urheber-/Nutzungsrechte</h4>
            <p>
                10.1 Soweit Urheberrechte und/oder gewerbliche Schutzrechte sowie Rechte an geistigem Eigentum im Zusammenhang mit dem Auftrag entstehen, ist die Nutzung dieser Rechte dem Auftraggeber nur im Rahmen des Auftrags bzw. der Veranstaltung gestattet. Eine Übertragung von derartigen Rechten findet nicht statt.<br/><br/>
                10.2 Entwickelte Ideen und Konzepte, welche dem Auftraggeber bekannt geworden sind, dürfen von dem Auftraggeber nicht verwendet werden, es sei denn, die Agentur hat hierfür ihre ausdrückliche Befugnis erteilt. Vorstehendes gilt auch für etwaige im Vorfeld zum Vertragsschluss mitgeteilte Informationen.<br/><br/>
                10.3 Bekannt gewordene Entwürfe oder sonstige Leistungen der Agentur dürfen vom Auftraggeber unabhängig davon, ob die Leistungen dem Urheberrechtsgesetz unterliegen, nicht ohne Zustimmung der Agentur verwendet, verändert, veröffentlicht oder weitergegeben werden.<br/><br/>
                10.4 Soweit nichts anderes vereinbart wird, liegt das Eigentum angefallenen Materials und angefallener Daten im Rahmen der Durchführung des Auftrags bei der Agentur. Das Urheberrecht des Auftraggebers an Unterlagen, die er erarbeitet hat, bleibt hiervon unberührt.<br/><br/>
                10.5 Sofern mit dem Auftraggeber schriftlich nichts Anderweitiges vereinbart ist, hat die Agentur vorbehaltlich entgegenstehender Rechte Dritter das Recht, im Rahmen der Eigenwerbung den vom Auftraggeber erteilten Auftrag und seine Durchführung einschließlich bei dieser Gelegenheit erstellten Bildmaterials zu veröffentlichen, zu vervielfältigen zu verbreiten oder in sonstiger Weise zu verwerten.<br/><br/>
                10.6 Der Auftraggeber hat dafür Sorge zu tragen, dass bei Vermittlung oder Zurverfügungstellung urheberrechtlich geschützter Werke Rechte Dritter nicht beeinträchtigt werden. Dies gilt auch für anderweitig geschütztes geistiges Eigentum Dritter. Für den Fall einer Verletzung derartiger Schutzrechte und einer hieraus resultierenden Inanspruchnahme der Agentur hat der Auftraggeber die Agentur von jeglichen Ansprüchen freizustellen.<br/><br/>
            </p>

            <h4>11. Leistungen Dritter</h4>
            <p>
                11.1 Zur Durchführung eines Auftrags werden regelmäßig Verträge mit Dritten im Namen der Agentur und für eigene Rechnung geschlossen. Die hierfür entstehenden Kosten werden dem Auftraggeber von der Agentur in Rechnung gestellt.<br/><br/>
                11.2 Auf Wunsch des Auftraggebers übernimmt die Agentur die Beauftragung von Künstlern und anderen Dritten sowie deren Koordinationen. In diesem Fall werden die diesbezüglichen Verträge ausschließlich im Namen des Auftraggebers abgeschlossen. Insoweit wird die Agentur lediglich als Vermittler und/oder Koordinator tätig. Für solchermaßen engagierte Künstler durch Dritte und für deren Erfüllung ihrer Leistungspflicht übernimmt die Agentur keine Haftung.<br/><br/>
            </p>

            <h4>12. Mitwirkungspflicht des Kunden</h4>
            <p>
                12.1 Der Kunde ist verpflichtet der Agentur sämtliche Informationen zur Verfügung zu stellen, die für die Erfüllung der vereinbarten Leistungen notwendig sind.<br/><br/>
                12.2 Der Kunde ist verpflichtet vereinbarte Leistungen die der Erfüllung des Events dienlich sind, unverzüglich bereit zu stellen oder unaufgefordert umzusetzen.<br/><br/>
                12.3 Der Kunde benennt der Agentur einen Ansprechpartner, der für die Durchführung des jeweiligen Events vom Kunden mit Kompetenzen ausgestattet ist und die Verantwortung der beauftragten Leistungen trägt. Der Kunde sorgt dafür, dass der Ansprechpartner ständig zu erreichen und bei dem Event örtlich vertreten ist. Ändert sich die Person des Ansprechpartners, so ist dies der Agentur unverzüglich schriftlich mitzuteilen.<br/><br/>
                12.4 Entsteht der Agentur durch Verletzung der Mitwirkungspflicht durch den Kunden (z.B. unvollständiges oder falsches Material, Dokumente, Adresslisten, Pläne oder Abläufe) gegenüber den ursprünglichen Vorgaben ein Mehraufwand und/oder müssen zusätzliche Leistungen erbracht werden, ist die Agentur berechtigt, eine zusätzliche Vergütung zu berechnen.<br/><br/>
                12.5 Verletzt der Kunde seine Mitwirkungspflichten, so ist die Agentur berechtigt nach den gesetzlichen Vorschriften vom Vertrag zurückzutreten. Die sonstigen Rechte der Agentur bleiben unbeschadet.<br/><br/>
            </p>

            <h4>13. Memos</h4>
            <p>
                Erhält der Kunde von der Agentur Besprechungs-, Meeting- oder Telefon-Protokolle (Memos), dienen diese der Agentur als verbindliche Arbeitsunterlagen, sofern sie nach Erhalt durch den Kunden nicht innerhalb von 3 Werktagen schriftlich korrigiert werden.<br/><br/>
            </p>

            <h4>14. Geheimhaltung/Datenschutz</h4>
            <p>
                14.2 Informationen, die die Agentur dem Auftraggeber vor Vertragsschluss zu dem jeweiligen Vorhaben zukommen lässt, erhält der Auftraggeber lediglich zur Entscheidung über die Auftragsvergabe des angebotenen Projektes. Eine anderweitige Verwendung der Informationen ist nur gestattet, soweit die Agentur hierzu ihre Zustimmung erteilt.<br/><br/>
                14.2 Es wird daraufhin gewiesen, dass die Agentur für die Vertragsabwicklung gemäß den Bestimmungen des Bundesdatenschutzgesetzes sich der elektronischen Datenverarbeitung bedient. Personenbezogene Daten des Auftraggebers und an der Vertragsabwicklung beteiligter Dritter, soweit sie für die Vertragsabwicklung von Bedeutung sind, werden bei der Agentur gespeichert.<br/><br/>
            </p>

            <h4>15. Nutzungsgebühren sowie sonstige Abgaben</h4>
            <p>
                Für alle Veranstaltungen verpflichtet sich die Agentur, die Abgaben für die Nutzung von Rechten wie z.B. GEMA sowie evtl. anfallende Beträge zur Künstlersozialkasse abzuführen. Dies gilt jedoch nicht für solche Abgaben und Beiträge, die unter § 10 fallen. Soweit anfallende Abgaben und Beiträge im Voraus nicht zu ermitteln sein sollten, so werden diese nachträglich gesondert abgerechnet und vom Auftraggeber erstattet.<br/><br/>
            </p>

            <h4>16. Erfüllungsort, Gerichtsstand und anwendbares Recht</h4>
            <p>
                16.1 Sind einzelne Bestimmungen des Vertrages oder dieser Bedingungen ganz oder teilweise unwirksam oder nichtig, so bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt.<br/><br/>
                16.2 Ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit dem Vertragsverhältnis der Parteien ist der Sitz der Agentur.<br/><br/>
                16.3 Diese Bedingungen sowie das Vertragsverhältnis der Parteien unterliegen dem Recht der Bundesrepublik Deutschland.<br/><br/>
            </p>
            <Link to='/'><button className='enter-button red'>Home</button></Link>
        </main>
    )
}
